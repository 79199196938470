import React, { useMemo } from 'react'

import Button from '../button'

import styles from './style.module.scss'
import { useOwnerStats } from '../hooks'
import { get } from 'lodash-es'
import { useCurrentUser } from '../protected-page'
import Loader from '../loader'

export default () => {
    const { response, loading } = useOwnerStats()

    const [user] = useCurrentUser()

    const stats = useMemo(() => get(response, 'data') || {}, [response])

    return (
        <>
            {loading && <Loader />}
            <div className={styles.container}>
                <div className={styles.gettingStartedContainer}>
                    <div className={styles.gettingStartedHeader}>
                        <h3 className={styles.gettingStartedTitle}>
                            Getting started
                        </h3>
                    </div>
                    <div className={styles.gettingStartedBody}>
                        <div className={styles.gettingStartedHello}>
                            Hello {user.display_name},
                        </div>
                        <p className={styles.gettingStartedDescription}>
                            Here are few things you can do with Property ePortal
                        </p>
                        <div className={styles.gettingStartedItem}>
                            <span>Add a property</span>
                            <Button
                                className={styles.startButton}
                                link="/owner-property"
                            >
                                Start
                            </Button>
                        </div>
                        <div className={styles.gettingStartedItem}>
                            <span>Review your offers</span>
                            <Button
                                className={styles.startButton}
                                link="/owner-offers"
                            >
                                Start
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={styles.statsContainer}>
                    <div className={styles.stats}>
                        <h3 className={styles.statsTitle}>Total Properties</h3>
                        <div className={styles.statsValue}>
                            {stats.total_properties}
                        </div>
                    </div>
                    <div className={styles.stats}>
                        <h3 className={styles.statsTitle}>
                            Approved Properties
                        </h3>
                        <div className={styles.statsValue}>
                            {stats.approved_properties}
                        </div>
                    </div>
                    <div className={styles.stats}>
                        <h3 className={styles.statsTitle}>
                            Pending Properties
                        </h3>
                        <div className={styles.statsValue}>
                            {stats.pending_properties}
                        </div>
                    </div>
                    <div className={styles.stats}>
                        <h3 className={styles.statsTitle}>Offers</h3>
                        <div className={styles.statsValue}>
                            {stats.total_offers}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
