import React from 'react'
import { FaSpinner } from 'react-icons/fa'

import styles from './style.module.scss'

export default () => {
    return (
        <div className={styles.container}>
            <FaSpinner />
        </div>
    )
}
